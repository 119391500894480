<div id="containerLogin1">

    <div id="izqLogin">
        <div class="text-center">
            <h2>DATA PROTECTION POLICY AND CONSENT OF THE CHANNEL OF COMMUNICATIONS</h2>
            <hr>
            <div class="text-left">
                <p>
                    In order to receive and process your communication, you must confirm that you have read this Privacy Notice. If you do not agree to the terms and conditions as set out in the notice, you will not be able to make the communication.
                </p>
                <p>
                    In this regard and in compliance with the provisions of Article 13 of Regulation (EU) 2016/679 on the protection of individuals with regard to the processing of personal data and on the free movement of such data (General Data Protection Regulation or
                    GDPR), detailed information on the processing of personal data within the framework of the Communication Channel is set out below.
                </p>
                <p>
                    This communication channel is managed by the owner of the website in which it is integrated.
                </p>
                <p>
                    The Controller guarantees the security and confidentiality of the data collected for the purpose of processing, in accordance with this Privacy Notice.
                </p>
            </div>
        </div>
    </div>
</div>





<div id="containerLogin2">
    <br>
    <div class="text-left">
        <p>Here is some basic information about how we handle your personal data:
        </p>
        <br>
        <table class="table">
            <tbody>
                <tr>
                    <td>
                        <strong>Data processed and origin</strong>
                    </td>
                    <td>
                        <p>- Contact details, if you provide them (name, surname, e-mail address and telephone number). In any case, the communication may be submitted anonymously.</p>
                        <p>- Name and position of the persons you report on in the communication, either as involved or as witnesses.</p>
                        <p>- Information on reported facts that may contain personal data. </p>

                    </td>
                </tr>
                <tr>
                    <td>
                        <strong>Purpose of processing </strong>
                    </td>
                    <td>
                        In accordance with current legislation on data protection, we inform you that your personal data will be collected and processed for the proper management of our internal information system, processing the corresponding irregularities reported through
                        the Communication Channel, and deciding on the appropriateness of initiating an investigation, in order to detect possible infringements, as well as to prevent any type of conduct contrary to the regulations.
                    </td>
                </tr>
                <tr>
                    <td>
                        <strong>Legitimation of the processing</strong>
                    </td>
                    <td>
                        The processing of your data finds its legitimacy in the legal obligation established in article 6.1.c) RGPD).
                    </td>
                </tr>
                <tr>
                    <td>
                        <strong>Reservation of the informant's identity</strong>
                    </td>
                    <td>
                        <p>
                            We inform you that your identity will in any case remain confidential and will not be disclosed to the persons to whom the facts reported relate or to third parties.
                        </p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <strong>Addressees </strong>
                    </td>
                    <td>
                        <p>
                            Unless legally obliged to do so, your data will only be disclosed to the following categories of recipients:
                        </p>
                        <p>
                            - Courts and tribunals, as well as other possible dispute resolution bodies.
                        </p>
                        <p>
                            - State Security Forces and Corps.
                        </p>
                        <p>
                            Notaries and registrars.
                        </p>
                        <p>
                            We have signed the necessary confidentiality and personal data processing contracts with suppliers who need access to your personal data in order to provide the services we have contracted with them or who, due to the operation of our electronic services
                            (website and e-mails), may have access to certain personal data, as required by law to protect your privacy (article 28.3 RGPD).
                        </p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <strong>Conservation, Management and Custody Criteria </strong>
                    </td>
                    <td>
                        <p>
                            We will retain your data for a maximum period of three months after notification of the irregularity, if the facts have not been proven, and as long as they are not necessary for other purposes or for the purposes of evidence of proper control and supervision
                            in the prevention of criminal offences.
                        </p>
                        <p>
                            In the event that the facts are proven or with sufficient evidence, the data shall be retained for as long as necessary for the entity to exercise its rights before the courts of law, and when it is no longer necessary for that purpose, the data shall
                            be deleted with appropriate security measures to ensure the anonymisation of the data or the total destruction of the data.
                        </p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <strong>Rights of data subjects</strong>
                    </td>
                    <td>
                        <p>
                            You have the right to obtain confirmation of the processing of your personal data by this entity. Specifically, you have the following rights:
                        </p>
                        <ul>
                            <li>
                                Rights of access, rectification, portability and deletion of your data, and of limitation or opposition to their processing.
                            </li>
                            <li>
                                The right to lodge a complaint with the supervisory authority (www.aepd.es) if you consider that the processing does not comply with the regulations in force.
                            </li>
                        </ul>
                    </td>
                </tr>
                <tr>
                    <td>
                        <strong>Cookies</strong>
                    </td>
                    <td>
                        <p>
                            The Communication Channel does not use cookies to collect information from users. Only session cookies are used for technical purposes (those that allow the user to navigate through the digital platform and use the different options and services that
                            exist therein) and registration cookies.
                        </p>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div style="height: 150px;"></div>
</div>