<app-empresa></app-empresa>

<div id="pasos">
    <div id="headerPasos">
        <strong>Total Communications</strong>
        <p>List of all registered communications.</p>

    </div>
    <div class="text-center">
        <div class="mb-5" *ngIf="archivosAnadidos">
            <h5>Se han añadido los archivos</h5>
        </div>
        <div class="mb-5" *ngIf="notasAnadidas">
            <h5>Se ha añadido la nota</h5>
        </div>
        <div class="mb-5" *ngIf="cambiosAnadidos">
            <h5>Se ha realizado los cambios</h5>
        </div>

        <nav class="navbar navbar-light" style="float:right;">
            <div class="container-fluid">
                <form class="d-flex">
                    <div id="filtros" style="display:flex">

                        <div (click)="toggleShow()" id="emojiFiltros" class="text-center" style="margin-left:-10px">
                            <button class="btn" type="button"> <span>Order <i class="bi bi-chevron-compact-down" >
                                    </i></span></button>
                        </div>
                        <div *ngIf="isShown" class="row container-fluid text-left" id="divshow">
                            <ul>
                                <li style="padding:3px;border-bottom: 1px solid rgba(19, 81, 117, 0.178);" (click)="orden = 1; filtrar()">
                                    <i class="bi bi-sort-down-alt"></i>Date
                                </li>
                                <li style="padding:3px;border-bottom: 1px solid rgba(19, 81, 117, 0.178);" (click)="orden = 2; filtrar()">
                                    <i class="bi bi-sort-up"></i>Date
                                </li>
                                <!-- <li (click)="getDenuncias(3)"><i class="bi bi-sort-alpha-down"></i>Categoría</li> -->
                            </ul>
                        </div>
                    </div>
                    <input (ngModelChange)="filtrar()" [formControl]="filtro" class="form-control me-2" type="search" [placeholder]="placeholderFiltro" size="50" aria-label="Search">
                    <!-- <button class="btn btn-outline-primary" type="submit" id="submitNav"><i
                            class="bi bi-search"></i></button> -->
                    <div id="filtros" style="display:flex">
                        <div (click)="toggleShow2()" id="emojiFiltros" class="text-center" style="margin-left:-10px">
                            <button class="btn" type="button"> <span>Filter <i class="bi bi-chevron-compact-down" >
                                        </i></span></button>
                        </div>
                        <div *ngIf="isShown2" class="row container-fluid text-left" id="divshow">
                            <ul>
                                <li style="padding:3px;border-bottom: 1px solid rgba(19, 81, 117, 0.178);" (click)="tipoFiltro = 0; filtrar()">
                                    Filtro by ID
                                </li>
                                <li style="padding:3px;border-bottom: 1px solid rgba(19, 81, 117, 0.178);" (click)="tipoFiltro = 1; filtrar()">
                                    Filtro by Category
                                </li>
                                <!-- <li (click)="getDenuncias(3)"><i class="bi bi-sort-alpha-down"></i>Categoría</li> -->
                            </ul>
                        </div>
                    </div>
                </form>
            </div>
        </nav>
        <table class="tablaprincipal">
            <thead>
                <tr>
                    <th></th>
                    <th>ID</th>
                    <th>CATEGORY</th>
                    <th>STATUS</th>
                </tr>
            </thead>
            <tbody *ngFor="let denuncia of denuncias_filtradas; let i = index">
                <tr>
                    <td><button class="btn btn-link" type="button" id="more" (click)="masDetalles(i)"><i
                                class="bi bi-arrow-90deg-down"></i></button></td>
                    <td style="font-weight: bold;">{{denuncia.codigo_identificacion}}</td>
                    <td style="font-weight: bold;">{{denuncia.categoria}}</td>
                    <td *ngIf="denuncia.estado">
                        <span style="font-weight: bold;" *ngIf="denuncia.estado==='recibida'">Received</span>
                        <span style="font-weight: bold;" *ngIf="denuncia.estado==='tramitada'">Processing</span>
                        <span style="font-weight: bold;" *ngIf="denuncia.estado==='resuelta'">Resolved</span>
                    </td>
                </tr>

                <tr id="show" *ngIf="show[i]">
                    <td>
                        <button class="btn btn-outline-primary" (click)="onSubmit(i, denuncia.id_denuncia, denuncia.codigo_identificacion)" type="button" id="save">SAVE</button>
                    </td>
                    <td>
                        <ul id="botones" class="text-center botonesdetalle">
                            <br>
                            <li>View Files:
                                <br>
                                <button (click)="showArchivos(i, denuncia.id_denuncia)">
                                <i class="bi bi-file-earmark-play"></i>
                                </button>
                            </li>
                            <hr>
                            <li>More detail:
                                <br>
                                <button (click)="aunMasDetalles(i, denuncia.id_denuncia)">
                                <i class="bi bi-plus-circle"></i>
                                </button>
                            </li>
                            <!-- <li class="file-input-wrapper">
                                <button type="button" style=" padding:5px; margin:0;border-radius:12px; font-weight: 600;">Añadir archivos</button>
                                <input type="file" name="image" id="image" value="" multiple />
                            </li> -->

                        </ul>
                    </td>

                    <td>
                        Modify category<select class="custom-select " [formControl]="categoria[i]">
                            <option selected disabled value="">Select an option</option>
                            <option *ngFor="let categoria of categorias" [value]="categoria.titulo">{{categoria.titulo}}</option>

                <!-- <option value="Protección de los consumidores">Protección de los consumidores</option>
                <option value="Protección del medioambiente">Protección del medioambiente</option>
                <option value="Protección de los datos personales e intimidad">Protección de los datos personales e intimidad
                </option>
                <option value="Salud pública">Salud pública</option>
                <option value="Seguridad en productos">Seguridad en productos</option>
                <option value="Seguridad de los alimentos">Seguridad de los alimentos</option>
                <option value="Seguridad en el transporte">Seguridad en el transporte</option>
                <option value="Seguridad de las redes y sistemas de información">Seguridad de las redes y sistemas de información</option>
                <option value="Competencia">Competencia</option>
                <option value="Contratación pública">Contratación pública</option>
                <option value="Otros">Otros</option> -->
                        </select>
                    </td>
                    <td>
                        <!-- <ul style="width: 200px;" id="botones" class="text-center"> -->
                        <!-- <ul style="width: 100%;" id="botones" class="text-center"> -->
                        <ul id="botones" class="text-center botonestado">
                            <li>


                                Modify status <select class="custom-select " [formControl]="estado[i]">
                            <option selected disabled value="">Select an option</option>
                            <option value="recibida">Received</option>
                            <option value="tramitada">Processing</option>
                            <option value="resuelta">Resolved</option>
                        </select>
                            </li>
                            <li *ngIf="estado[i].value === 'resuelta'">
                                <hr>
                                <div class="file-input-wrapper" style="float: right;">
                                    <button type="button" class="botonañadir" style=" padding:5px; margin:0;border-radius:12px; font-weight: 600;">Add resolution file</button>
                                    <input type="file" (change)="fileChangeEvent($event, denuncia.id_denuncia, denuncia.codigo_identificacion, resolucion = 1)" name="image" id="image" value="" />
                                    <br><small *ngIf="archivoASubir.length > 0">Resolution file added</small>
                                </div>
                            </li>
                        </ul>
                    </td>
                </tr>
                <tr id="show" *ngIf="show[i]">
                    <td colspan="4">
                        <br>
                        <div class="input-group mb-3">
                            <textarea type="text" [formControl]="nota" class="form-control" placeholder="Add Notes . . ." aria-describedby="button-addon2">  </textarea>
                            <button (click)="addNota(denuncia.id_denuncia, denuncia.codigo_identificacion)" class="btn btn-outline-primary" type="button" id="button-addon2">
                                <!-- <i class="bi bi-forward-fill"></i> -->
                                Add Notes
                                </button>
                        </div>

                    </td>
                </tr>

                <tr *ngIf="detallesshow[i]" style="background-color: none; ">

                    <td colspan="4" style="background-color: none;" id="archivos">

                        <div style="float:left; margin-left:35px; ">
                            <button class="btn btn-link" type="button" id="more" style="
                            margin-top:15px;" (click)="aunMasDetalles(i)"><i
                                    class="bi bi-arrow-90deg-up"></i></button>
                            <br><br>


                        </div>


                        <div class="container">

                            <h5 style="margin-top:50px; font-weight: 700;">Communication details</h5>
                            <hr>

                            <div class="row justify-content-between">
                                <div class="col-4 mr-2 ml-2">

                                    <h6>Date of communication</h6>

                                </div>
                                <div class="col-5 mr-2 ml-2">
                                    <p>{{fecha_denuncia[i]}}</p>
                                </div>
                            </div>
                            <hr>

                            <div class="row justify-content-between">
                                <div class="col-4 mr-2 ml-2">

                                    <h6>Relationship with the Club:</h6>

                                </div>
                                <div class="col-5 mr-2 ml-2">
                                    <p>{{denuncia.relacion}}</p>
                                </div>
                            </div>
                            <hr>

                            <div class="row justify-content-between">
                                <div class="col-4 mr-2 ml-2">

                                    <h6>Communication category:</h6>

                                </div>
                                <div class="col-5 mr-2 ml-2">
                                    <p>{{denuncia.categoria}}</p>
                                </div>
                            </div>
                            <hr>

                            <div class="row justify-content-between">
                                <div class="col-4 mr-2 ml-2">

                                    <h6>Date of the event</h6>

                                </div>
                                <div class="col-5 mr-2 ml-2">
                                    <p *ngIf="denuncia.fecha_suceso">{{denuncia.fecha_suceso}}</p>
                                    <p *ngIf="!denuncia.fecha_suceso">Not specified</p>
                                </div>
                            </div>
                            <hr>

                            <div class="row justify-content-between">
                                <div class="col-4 mr-2 ml-2">

                                    <h6>Annotated with address</h6>

                                </div>
                                <div class="col-5 mr-2 ml-2">
                                    <p>{{denuncia.comentado}}</p>
                                </div>
                            </div>
                            <hr>

                            <div class="row justify-content-between">
                                <div class="col-4 mr-2 ml-2">

                                    <h6>Implicated</h6>

                                </div>
                                <div class="col-5 mr-2 ml-2">
                                    <p>{{denuncia.implicados}}</p>
                                </div>
                            </div>
                            <hr>

                            <div class="row justify-content-start">
                                <div class="col-4 mr-2 ml-2">

                                    <h6>Details of those involved:</h6>

                                </div>
                            </div>
                            <div class="row justify-content-between">
                                <div class="col-12 ">
                                    <table class="table testigos">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Position</th>
                                                <th>Events</th>
                                                <th>Telephone/Email</th>
                                                <th>Address</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let implicado of implicados">
                                                <td>{{implicado.nombre_implicado}}</td>
                                                <td>{{implicado.cargo_implicado}}</td>
                                                <td>{{implicado.hechos_acontecidos_implicado}}</td>
                                                <td>{{implicado.contacto_implicado}}</td>
                                                <td>{{implicado.domicilio_implicado}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <hr>

                            <div class="row justify-content-start">
                                <div class="col-4 mr-2 ml-2">

                                    <h6>Witness Data:</h6>

                                </div>
                            </div>
                            <div class="row justify-content-between">
                                <div class="col-12" *ngIf="testigos.length > 0">
                                    <table class="table testigos">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Events</th>
                                                <th>Telephone/Email</th>
                                                <th>Address</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let testigo of testigos">
                                                <td>{{testigo.nombre_testigo}}</td>
                                                <!-- <td>{{testigo.cargo_testigo}}</td> -->
                                                <td>{{testigo.hechos_acontecidos_testigo}}</td>
                                                <td>{{testigo.contacto_testigo}}</td>
                                                <td>{{testigo.domicilio_testigo}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="col-12 mr-2 ml-2" *ngIf="testigos.length == 0">
                                    <h6>No witnesses added</h6>
                                </div>
                            </div>
                            <hr>

                            <div class="row justify-content-between">
                                <div class="col-12 mt-3 mr-2 ml-2">

                                    <h6>Description of the event</h6>

                                </div>
                            </div>
                            <div class="row justify-content-between">
                                <div class="col-12">
                                    <p>{{denuncia.explicacion_suceso}}</p>
                                </div>
                            </div>

                            <div *ngIf="detalles.length > 0">
                                <div class="row justify-content-between">
                                    <div class="col-12 mt-3 mr-2 ml-2">

                                        <h6>Added details</h6>

                                    </div>
                                </div>
                                <div class="row justify-content-between" *ngFor="let detalle of detalles; let k = index">
                                    <div class="col-2 mr-2 ml-2">

                                        <p>{{fecha_detalle[k]}}</p>

                                    </div>
                                    <div class="col-7 mr-2 ml-2">
                                        <p>{{detalle.detalle}}</p>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="notas.length > 0">
                                <div class="row justify-content-between">
                                    <div class="col-12 mt-3 mr-2 ml-2">

                                        <!-- <h6>Notas añadidas por el Colegio</h6> -->
                                        <h6>Added notes</h6>

                                    </div>
                                </div>
                                <div class="row justify-content-between" *ngFor="let nota of notas; let j = index">
                                    <div class="col-3 mr-2 ml-2">

                                        <p>{{fecha_nota[j]}}</p>

                                    </div>
                                    <div class="col-7 mr-2 ml-2">
                                        <p>{{nota.nota}}</p>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </td>
                </tr>
                <tr *ngIf="archivosshow[i]" style="background-color: none; ">

                    <td colspan="4" style="background-color: none;" id="archivos">

                        <div class="row container-fluid text-left">


                            <div style="float:left; margin-left:35px; ">
                                <button class="btn btn-link" type="button" id="more" style="
                                margin-top:15px;" (click)="showArchivos(i)"><i
                                        class="bi bi-arrow-90deg-up"></i></button>
                                <br><br>
                            </div>

                            <div id="listaArchivos" style="width: 90%;">
                                <h5 style="margin-top:50px; font-weight: 700;">Documents</h5>
                                <hr>
                                <!-- <div style="display: flex;" *ngIf="img.length > 0">
                                    <div *ngFor="let im of img; let j = index">
                                        <a [href]="im" target="_blank" download="archivo">Archivo {{j+1}}</a>
                                        <br><small>{{fechaArchivo[j]}}</small>
                                    </div>
                                    <div><span id="img_text"></span>
                                    </div>
                                </div> -->

                                <div class="row" *ngIf="img.length > 0">
                                    <div class="col-4" *ngFor="let im of img; let j = index">
                                        <a [href]="im" target="_blank" download="archivo">File {{j+1}}</a>
                                        <br><small>{{fechaArchivo[j]}}</small>
                                    </div>
                                    <div><span id="img_text"></span>
                                    </div>
                                </div>


                                <div style="display: flex;" *ngIf="img.length == 0">
                                    There's no files attached to this communication
                                </div>

                                <div class="file-input-wrapper" style="float: right;">
                                    <button type="button" class="botonañadir" style=" padding:5px; margin:0;border-radius:12px; font-weight: 600;">Add files</button>
                                    <input type="file" (change)="fileChangeEvent($event, denuncia.id_denuncia, denuncia.codigo_identificacion)" name="image" id="image" value="" multiple />
                                </div>
                                <div style="float: right;" *ngIf="archivosLoading">
                                    <mat-spinner diameter="35"></mat-spinner>
                                </div>

                            </div>

                            <!-- <div id="listaArchivos" style="width: 90%;">
                                <h5 style="margin-top:50px; font-weight: 700;">Listado de archivos</h5>
                                <hr>
                                <div style="display: flex;" *ngIf="img.length > 0">
                                    <div *ngFor="let im of img; let j = index">
                                        <a [href]="im" target="_blank" download="archivo">Archivo {{j+1}}</a>
                                        <br><small>{{fechaArchivo[j]}}</small>
                                    </div>
                                    <div><span id="img_text"></span>
                                    </div>
                                </div>
                                <div style="display: flex;" *ngIf="img.length == 0">
                                    No hay archivos asociados a esta denuncia
                                </div>

                                <div class="file-input-wrapper" style="float: right;">
                                    <button type="button" class="botonañadir" style=" padding:5px; margin:0;border-radius:12px; font-weight: 600;">Añadir archivos</button>
                                    <input type="file" (change)="fileChangeEvent($event, denuncia.id_denuncia, denuncia.codigo_identificacion)" name="image" id="image" value="" multiple />
                                </div>
                                <div style="float: right;" *ngIf="archivosLoading">
                                    <mat-spinner diameter="35"></mat-spinner>
                                </div>

                            </div> -->

                        </div>

                    </td>
                </tr>
            </tbody>
        </table>
        <br><br>


        <!--
        <div id="tableHome" style="margin-top:-50px;">
            <nav class="navbar navbar-light bg-light">
                <div class="container-fluid">
                    <a class="navbar-brand"><i class="bi bi-list-check"></i></a>
                    <form class="d-flex">

                        <div id="filtros" style="display:flex">
                    


                            <div (click)="opcionesPDF()">
                                <i class="bi bi-file-earmark-pdf" id="emojiFiltro"></i>
                            </div>
                            <div *ngIf="showOpciones" class="row container-fluid" id="divshow">
                                <ul style="display: flex; margin-left:-20px">
                                    <li><i class="bi bi-cloud-plus" id="emojiFiltro" (click)="subirPDF()"></i>
                                        <div *ngIf="showPDF" class="row container-fluid">
                                            <input type="file">
                                        </div>
                                    </li>
                                    <li> <i class="bi bi-eye" id="emojiFiltro"></i></li>
                                </ul>
                            </div>

                            <div (click)="toggleShow()">
                                <i class="bi bi-sliders" id="emojiFiltro"></i>
                            </div>
                            <div *ngIf="isShown" class="row container-fluid" id="divshow">
                                <ul>
                                    <li><i class="bi bi-sort-alpha-down"></i>Categoría</li>
                                    <li><i class="bi bi-sort-down-alt"></i>Fecha</li>
                                    <li><i class="bi bi-sort-up"></i>Fecha</li>
                                </ul>
                            </div>
                        </div>

                        <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search">
                        <button class="btn btn-outline-info" type="submit" id="submitNav">Buscar</button>
                    </form>
                </div>
            </nav>
            <table class="table table-hover text-center">
                <thead>
                    <tr>
                        <th></th>
                        <th>ID</th>
                        <th>CATEGORÍA</th>
                        <th>ESTADO</th>
                        <th cols="2">AÑADIR NOTA</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td><input type="checkbox"></td>
                        <td>0001</td>
                        <td>Otros</td>
                        <td>Tramitando</td>
                        <td><span><input type="text" class="form-control" id="inputSubmit"><button type="submit"
                                    id="botonSubmit"><i class='bi bi-arrow-right-circle'></i></button></span></td>
                    </tr>
                </tbody>
            </table>

            <button type="submit" class="btn btn-primary" id="buttonDcha">Confirmar cambios</button>
    -->
    </div>

</div>
<div class="bottommobile" style="height: 200px;"></div>