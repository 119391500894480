<app-denunciante></app-denunciante>

<div id="pasos">
    <div id="headerPasos">
        <strong><i class="bi bi-house" style="font-size:2em"></i></strong>
        <p>Communication status updates of already registered communications are displayed.</p>
    </div>
    <div class="animate__animated animate__backInLeft">
        <div id="bodyPasos" class="text-center" style=" color: rgb(27, 81, 113);">
            <h2>Communication ID <br> <input required [(ngModel)]="codigo" type="text" size="15" id="inputID">
                <button type="submit" (click)="getDenuncia()"><i class="bi bi-forward-fill"></i></button>
            </h2>
            <div style="margin-top: 20px;"></div>
            <small *ngIf="captcha.invalid && captcha.touched" style="color: red;">Please, check the box:</small><br>
            <!-- <re-captcha class="captcha" (resolved)="resolved($event)" required [(ngModel)]="captcha"></re-captcha> -->
            <re-captcha class="captcha" size="normal" (resolved)="resolved($event)" [formControl]="captcha"></re-captcha>
            <hr>
            <br>

            <!-- <div id="opcionesEstado">
                <input [value]="0" [(ngModel)]="tramitacion" type="radio" id="recibido" name="tramitacion" [checked]="isChecked" style="margin-left:10px">   <label for="recibido">Recibido</label><br>   <input [value]="1" [(ngModel)]="tramitacion" type="radio"
                    id="tramitando" name="tramitacion" [checked]="!isChecked">   <label for="tramitando">Tramitando</label><br>   <input [value]="2" [(ngModel)]="tramitacion" type="radio" id="resuelta" name="tramitacion" [checked]="!isChecked">   <label for="resuelta">Resuelta</label>
            </div> -->

            <div *ngIf="tramitacion == 0">
                <i class="bi bi-send-check"></i>
                <br>
                <p>Communication recieved</p>
                <br>
                <p>COMMUNICATION CREATED {{dias}} DAYS AGO</p>
                <small>Maximum resolution deadline is 3 months</small>
            </div>
            <div *ngIf="tramitacion == 1">
                <i class="bi bi-hourglass-split"></i>
                <br>
                <p>Communication in process</p>
                <br>
                <p>COMMUNICATION CREATED {{dias}} DAYS AGO</p>
                <small>Maximum resolution deadline is 3 months</small>
            </div>
            <div *ngIf="tramitacion == 2">
                <i class="bi bi-check2-all"></i>
                <br>
                <p>Communication resolved.</p>
                <br>
                <p>RESOLUTION FILES:</p>
                <div *ngFor="let im of img; let j = index">
                    <a [href]="im" target="_blank" download="archivo">File {{j+1}}</a>
                </div>
                <!-- <p>DENUNCIA REALIZADA HACE {{dias}} DÍAS</p>
                <small>El plazo máximo de resolución es de 3 meses</small> -->
            </div>
            <div *ngIf="tramitacion == 3">
                <i class="bi bi-exclamation-circle"></i>
                <br>
                <p>There's no communication associated with the provided ID.</p>
            </div>
        </div>






    </div>
    <div style="height: 200px;"></div>