import { Component, OnInit } from '@angular/core';
import { ServicioDenunciaService } from '../../../../services/servicio-denuncia.service';
import { FormControl, Validators } from '@angular/forms';
import { GLOBAL } from 'src/app/services/global';

@Component({
  selector: 'app-denuncianteinicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.css']
})
export class InicioDenuncianteComponent implements OnInit {


  tramitacion: any;
  isChecked: boolean = true;

  public denuncia;
  public archivosResolucion;
  public img;
  public tiempo;
  public dias;
  public id = '';
  public codigo = '';
  // public captcha = '';
  public captcha = new FormControl('', Validators.required)

  constructor(private servicioDenuncia: ServicioDenunciaService) { }

  ngOnInit(): void {
  }

  getDenuncia(){

    if(this.captcha.invalid) {
      console.log('missing captcha');
      this.captcha.markAsTouched();
      this.captcha.markAsDirty();
      return
    }
    
    if (this.codigo.length > 0) {
      this.servicioDenuncia.getDenunciaByCodigo(this.codigo).subscribe(res => {
        if(res) {

          this.denuncia = res[0];
          this.id = this.denuncia.id_denuncia;
  
          let fecha_actual = new Date().getTime();
          this.tiempo = +fecha_actual - +this.denuncia.fecha_denuncia;
          let day = 24*60*60*1000;
  
          this.dias = (+this.tiempo / +day).toFixed(0);
  
          switch (this.denuncia.estado){
            case 'recibida': {
              this.tramitacion = 0;
              break;
            }
            case 'tramitada': {
              this.tramitacion = 1;
              break;
            }
            case 'resuelta': {
              this.servicioDenuncia.getArchivosResolucionById(this.denuncia.id_denuncia).subscribe( ar => {
                this.archivosResolucion = ar;
  
                this.img = [];
                let contadorar = 0;
                for (let i = 0; i < this.archivosResolucion.length; i++) {
                  // this.img[contadorar] = 'http://localhost/canalDenuncia2/uploads/' + this.archivosResolucion[i].archivo.slice(2, -2);
                  this.img[contadorar] = 'http://communicationchannel.euswift.org/canalDenuncia2/uploads/' + this.archivosResolucion[i].archivo.slice(2, -2);
                  // this.img[contadorar] = 'https://canaldenuncias.colegioveterinarios.net/canalDenuncia2/uploads/' + this.archivosResolucion[i].archivo.slice(2, -2);
                  // this.img[contadorar] = 'https://canaldenuncias.quimsaitw.com/canalDenuncia/uploads/' + this.archivosResolucion[i].archivo.slice(2, -2);
                  contadorar++;
                }
              });
              this.tramitacion = 2;
              break;
            }
          }
        } else {
          this.tramitacion = 3;
        }
      }, error => {
        console.log(<any>error);
        this.tramitacion = 3;
      });
    }
  }

  resolved(captchaResponse: string) {
    // console.log(`Resolved captcha with response: ${captchaResponse}`);
  }

}
