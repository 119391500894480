import { Component, OnInit } from '@angular/core';
import { NgForm, FormGroup, FormControl, FormArray, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ServicioDenunciaService } from '../../../services/servicio-denuncia.service';

@Component({
  selector: 'app-tres',
  templateUrl: './tres.component.html',
  styleUrls: ['./tres.component.css'],
  providers: [ServicioDenunciaService]
})
export class TresComponent implements OnInit {

  // ---------------------------------------------------------------------------------
  // Variables
  public aceptado = new FormControl(false);
  public denuncias = [];
  public idGenerado;
  public loading = false;
  
  // Variables para los archivos a subir
  public archivo = [];
  public previsualizacion: Array<string> = [];

  // Formulario de la denuncia
  public denuncia = new FormGroup ({
    fecha_denuncia: new FormControl(''),
    estado: new FormControl('recibida'),
    fecha_suceso: new FormControl(''),
    relacion: new FormControl(''),
    categoria: new FormControl(''),
    comentado: new FormControl(''),
    implicados: new FormControl(''),
    testigos: new FormControl(''),
    explicacion_suceso: new FormControl(''),
    anonima: new FormControl(true),
    nombre_denunciante: new FormControl(''),
    apellidos_denunciante: new FormControl(''),
    email_denunciante: new FormControl(''),
    telefono_denunciante: new FormControl(''),
    cargo_denunciante: new FormControl(''),
    provincia_denunciante: new FormControl(''),
    pais_denunciante: new FormControl(''),
    codigo_identificacion: new FormControl(''),
    clave_acceso: new FormControl(''),
  });

  // Array de formularios para implicados
  public implicadoFormArray = new FormArray([]);

  // Array de formularios para testigos
  public testigoFormArray = new FormArray([]);


  // ------------------------------------------------------------------------------------
  // Constructor y funciones

  constructor(private fb: FormBuilder, private _router: Router, private servicioDenuncia: ServicioDenunciaService) {
    // console.log(this.valueA);
  }

  // -------------------------------------------------------------------------------------
  // Guardar

  onSubmit() {
    // localStorage.setItem("denuncia", JSON.stringify(this.denuncia.value));
    // localStorage.setItem("implicado", JSON.stringify(this.implicadoFormArray.value));
    // localStorage.setItem("testigo", JSON.stringify(this.testigoFormArray.value));
    // localStorage.setItem("archivo", JSON.stringify(this.archivo));
    // this._router.navigate(["/cuatro"]);

    this.loading = true;


    if (this.denuncia.get('anonima').value) {
      this.denuncia.get('nombre_denunciante').setValue('');
      this.denuncia.get('apellidos_denunciante').setValue('');
      this.denuncia.get('cargo_denunciante').setValue('');
      this.denuncia.get('email_denunciante').setValue('');
      this.denuncia.get('telefono_denunciante').setValue('');
      this.denuncia.get('provincia_denunciante').setValue('');
      this.denuncia.get('pais_denunciante').setValue('');
    }

    let fecha = new Date().getTime();
    this.denuncia.get('fecha_denuncia').setValue(fecha);

    // console.log(this.denuncia.value);
    // console.log(this.implicadoFormArray.value);
    // console.log(this.testigoFormArray.value);
    // console.log(this.archivo);

    this.servicioDenuncia.getDenuncias().subscribe( (den: any) => {

      // Creamos un id con 10 caracteres
      this.makeid(10);

      // Comprobamos que es diferente al resto
      for (let j = 0; j < den.length; j++) {
        if (this.idGenerado === den[j].codigo_identificacion) {
          this.makeid(10);
          j = 0;
          continue;
        }
      }

      this.denuncia.get('codigo_identificacion').setValue(this.idGenerado);

      this.servicioDenuncia.addDenuncia(this.denuncia.value).subscribe( result => {

        console.log('Denuncia subida');

        this.getDenuncias();

        localStorage.removeItem('archivo');
        localStorage.removeItem('implicado');
        localStorage.removeItem('denuncia');
        localStorage.removeItem('testigo');

      }, error => {
        this.loading = false;
        window.alert('Ha ocurrido un error, inténtelo más adelante')
        console.log(<any>error);
      });
    });

  }

  makeid(length) {
    let result           = '';
    let characters       = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
     }
    this.idGenerado = result;
  }

  getDenuncias() {
    this.servicioDenuncia.getDenuncias().subscribe( (result: any) => {

      this.denuncias = result;

      // Buscamos la ultima id ya que es la de la denuncia que acabamos de subir anteriormente
      const id = this.denuncias[this.denuncias.length - 1].id_denuncia;

      // this.sendEmail(this.idGenerado);
      this.addImplicado(id);
      this.addTestigo(id);

      let fecha = new Date().getTime();

      let actualizacion = {
        idDenuncia: id,
        fecha_actualizacion: fecha,
        actualizacion: `A new communication has been created with ID ${this.idGenerado}`,
        color: 'rojo'
      };

      this.servicioDenuncia.addActualizacion(actualizacion).subscribe();

      this.loading = false;
      this._router.navigateByUrl('/confirmacion/' + this.idGenerado);

      if (this.archivo) {
        this.addArchivo(id);
      }

    }, error => {
      console.log(<any>error);
    });
  }

  sendEmail(id) {
    this.servicioDenuncia.sendEmail(id).subscribe( res => {
      console.log('Estado del email: ' + res);
    });
  }

  addImplicado(id) {

    for (let i = 0; i < this.implicadoFormArray.length; i++) {

      this.implicadoFormArray.controls[i].get('idDenuncia').setValue(id);

      this.servicioDenuncia.addImplicado(this.implicadoFormArray.controls[i].value).subscribe(res => {
        console.log('Implicado subido');
      }, error => {
        console.log(<any>error);
      });

    }

  }

  addTestigo(id) {

    for (let i = 0; i < this.testigoFormArray.length; i++) {

      this.testigoFormArray.controls[i].get('idDenuncia').setValue(id);

      this.servicioDenuncia.addTestigo(this.testigoFormArray.controls[i].value).subscribe(res => {
        console.log('Testigo subido');
      }, error => {
        console.log(<any>error);
      });

    }

  }

  addArchivo(id){
    for (let i = 0; i < this.archivo.length; i++) {
      let json = JSON.stringify(this.archivo[i]);
      let fecha = new Date().getTime();

      let archivodenuncias = {
        archivo: json,
        idDenuncia: id,
        fecha_archivo: fecha,
        resolucion: 0
      };

      //guardamos las imagenes para mobleku
      this.servicioDenuncia.addArchivo(archivodenuncias).subscribe( result => {
        console.log('Archivos subidos');
        },
        error => {
          console.log(<any>error);
        }
      );
    }
  }

  anterior() {

    // console.log(this.denuncia.value);
    // console.log(this.implicadoFormArray.value);
    // console.log(this.testigoFormArray.value);
    // console.log(this.archivo);

    localStorage.setItem("denuncia", JSON.stringify(this.denuncia.value));
    localStorage.setItem("implicado", JSON.stringify(this.implicadoFormArray.value));
    localStorage.setItem("testigo", JSON.stringify(this.testigoFormArray.value));
    // localStorage.setItem("archivo", JSON.stringify(this.archivo));
    this._router.navigate(["/dos"]);
  }


  // -------------------------------------------------------------------------------------------
  // Inicio

  ngOnInit(): void {

    // let fecha = new Date(1645522871097);
    // console.log(fecha.toLocaleString('it-IT'));

    // Recogemos los datos de anteriores formularios para la denuncia...
    this.denuncia.setValue(JSON.parse(localStorage.getItem("denuncia")));
    // console.log(this.denuncia.value);


    // Para los archivos
    this.archivo = JSON.parse(localStorage.getItem("archivo"));
    // console.log(this.archivo);


    // Para los implicados...
    let implicadosGuardados = JSON.parse(localStorage.getItem("implicado"));
    // console.log(implicadosGuardados);


    for (let i = 0; i < implicadosGuardados.length; i++) {

      let implicadoF = this.fb.group({
        idDenuncia: [implicadosGuardados[i].idDenuncia],
        nombre_implicado: [implicadosGuardados[i].nombre_implicado],
        cargo_implicado: [implicadosGuardados[i].cargo_implicado],
        hechos_acontecidos_implicado: [implicadosGuardados[i].hechos_acontecidos_implicado],
        contacto_implicado: [implicadosGuardados[i].contacto_implicado],
        domicilio_implicado: [implicadosGuardados[i].domicilio_implicado],
      });

      this.implicadoFormArray.push(implicadoF);
    }
    // console.log(this.implicadoFormArray.value);


    // Y para los testigos
    let testigosGuardados = JSON.parse(localStorage.getItem("testigo"));

    // console.log(testigosGuardados);

    if (this.denuncia.get('testigos').value != 0) {
      for (let i = 0; i < testigosGuardados.length; i++) {

        let testigoF = this.fb.group({
          idDenuncia: [testigosGuardados[i].idDenuncia],
          nombre_testigo: [testigosGuardados[i].nombre_testigo],
          cargo_testigo: [testigosGuardados[i].cargo_testigo],
          hechos_acontecidos_testigo: [testigosGuardados[i].hechos_acontecidos_testigo],
          contacto_testigo: [testigosGuardados[i].contacto_testigo],
          domicilio_testigo: [testigosGuardados[i].domicilio_testigo],
        });

        this.testigoFormArray.push(testigoF);
      }
    }
    // console.log(this.testigoFormArray.value);
  }

}
