<div id="navbar">
    <ul class="nav nav-pills nav-justified">
        <li class="nav-item" routerLinkActive="active">
            <a class="nav-link" routerLink="/home"><i class="bi bi-house"></i><br>Home</a>
        </li>
        <li class="nav-item" routerLinkActive="active">
            <a class="nav-link" routerLink="/listado"><i class="bi bi-list-check"></i><br>Total Communications</a>
        </li>
        <li class="nav-item" routerLinkActive="active">
            <a class="nav-link" (click)="logout()" routerLink="/login"><i class="bi bi-lock"></i><br>Exit</a>
        </li>

    </ul>
</div>


<router-outlet></router-outlet>