<app-denunciante></app-denunciante>

<div id="pasos">
    <div id="headerPasos">
        <strong>Add further information to the communication</strong>
        <p>Fill out with the provided communication ID that you wish to modify</p>
    </div>
    <div class="animate__animated animate__backInUp" style="margin-bottom: 40px;">
        <br>
        <div class="text-center">
            <h2>Communication ID <input type="text" [formControl]="codigo" size="15" id="inputID">
                <button class="botonFlecha" type="submit" (click)="getDenuncia()"><i class="bi bi-forward-fill"></i></button>
            </h2>
            <small *ngIf="captcha.invalid && captcha.touched" style="color: red;">Please, check the box:</small><br>
            <!-- <re-captcha class="captcha" (resolved)="resolved($event)" required [(ngModel)]="captcha"></re-captcha> -->
            <re-captcha class="captcha" [formControl]="captcha" data-size="normal" size="normal" data-theme="dark"></re-captcha>
            <hr>
        </div>
        <br><br>

        <div *ngIf="denuncia">
            <div *ngIf="denuncia.estado != 'resuelta'">

                <div id="div">
                    <div id="color">
                        <h6>Do you wish to add further involved person(s)?
                            <button class="btn" type="button" id="more" style="width: 100%;" (click)="toggleShow()" style="padding:0;">
                            <i class="bi bi-chevron-compact-down" style="padding:10px;"></i></button>
                        </h6>

                    </div>
                    <div class="text-center" id="open2" *ngIf="implicantes">
                        <br>
                        <table class="table table-striped" id="my-table">
                            <thead class="text-center">
                                <tr class="headerdesktop">
                                    <th id="asterisco">Name</th>
                                    <th id="asterisco">Position</th>
                                    <th id="asterisco">Events</th>
                                    <th>Telephone/Email</th>
                                    <th>Address</th>
                                    <th></th>
                                </tr>
                                <tr class="headermobile" *ngFor="let implicadoForm of implicadoFormArray.controls; let i = index">
                                    <th id="asterisco">Name</th>
                                    <th id="asterisco">Position</th>
                                    <th id="asterisco">Events</th>
                                    <th>Telephone/Email</th>
                                    <th>Address</th>
                                    <th style="border-bottom 1px solid grey"></th>
                                    <div style="height: 25px"></div>
                                </tr>
                            </thead>
                            <tbody class="text-left">
                                <tr class="p-3 mb-2 bg-light text-dark" *ngFor="let implicadoForm of implicadoFormArray.controls; let i = index" [formGroup]="implicadoForm">
                                    <td><input type="text" class="form-control" formControlName="nombre_implicado" required> </td>
                                    <td><input type="text" class="form-control" formControlName="cargo_implicado" required></td>
                                    <td><input type="text" class="form-control" formControlName="hechos_acontecidos_implicado" required></td>
                                    <td><input type="text" class="form-control" formControlName="contacto_implicado"></td>
                                    <td><input type="text" class="form-control" formControlName="domicilio_implicado"></td>
                                    <td><button type="button" (click)="eliminarImplicado(i)" id="addRowChild" class="btn btn-outline-danger">Remove</button></td>
                                    <div class="headermobile" style="height:25px"></div>

                                </tr>
                            </tbody>

                        </table>
                        <button type="button" (click)="agregarImplicado()" id="addRowChild" class="btn btn-outline-primary">Add those involved</button>
                        <!-- <button (click)="agregarImplicado()" type="submit" style="float:right" class="btn btn-outline-primary" id="addRowChild">Añadir
                        implicante</button> -->
                        <!-- <br> <br><br> -->
                    </div>
                </div>



                <div id="div">
                    <div id="color">
                        <h6> Do you wish to add further witnesses?
                            <button class="btn" type="button" id="more" style="width: 100%;" (click)="toggleT()" style="padding:0;">
                            <i class="bi bi-chevron-compact-down" style="padding:10px;"></i></button>
                        </h6>
                    </div>

                    <div *ngIf="testigos" class="text-center">
                        <br>
                        <table class="table table-striped" id="my-table">
                            <thead class="text-center">
                                <tr class="headerdesktop">
                                    <th id="asterisco">Name</th>
                                    <th id="asterisco">Events</th>
                                    <th>Telephone/Email</th>
                                    <th>Address</th>
                                    <th></th>
                                </tr>
                                <tr class="headermobile" *ngFor="let testigoForm of testigoFormArray.controls; let j = index">
                                    <th id="asterisco">Name</th>
                                    <th id="asterisco">Events</th>
                                    <th>Telephone/Email</th>
                                    <th>Address</th>
                                    <th style="border-bottom 1px solid grey"></th>
                                    <div style="height: 25px"></div>
                                </tr>

                            </thead>
                            <tbody class="text-left">
                                <tr class="p-3 mb-2 bg-light text-dark" *ngFor="let testigoForm of testigoFormArray.controls; let j = index" [formGroup]="testigoForm">
                                    <td><input type="text" class="form-control" formControlName="nombre_testigo" required></td>
                                    <!-- <td><input type="text" class="form-control" formControlName="cargo_testigo" required></td> -->
                                    <td><input type="text" class="form-control" formControlName="hechos_acontecidos_testigo" required></td>
                                    <td><input type="text" class="form-control" formControlName="contacto_testigo"></td>
                                    <td><input type="text" class="form-control" formControlName="domicilio_testigo"></td>
                                    <td><button type="button" (click)="eliminarTestigo(j)" id="addRowChild" class="btn btn-outline-danger">Remove</button></td>
                                    <div class="headermobile" style="height:25px"></div>
                                </tr>
                            </tbody>

                        </table>

                        <button type="button" (click)="agregarTestigo()" class="btn btn-outline-primary" id="addRowChild">Add those witnesses</button>
                        <!-- <button type="submit" style="float:right" class="btn btn-outline-primary" id="addRowChild">Añadir
                        testigo</button> -->
                        <!-- <br> <br><br> -->
                    </div>
                </div>


                <div id="div">
                    <div id="color">
                        <h6> Do you wish to add further details?
                            <button class="btn" type="button" id="more" style="width: 100%;" (click)="toggleD()" style="padding:0;">
                            <i class="bi bi-chevron-compact-down" style="padding:10px;"></i></button>
                        </h6>
                    </div>
                    <div *ngIf="detalle">
                        <br>
                        <textarea [formControl]="detalles" id="detalles" name="detalles" class="form-control" rows="6" cols="155" required></textarea>
                    </div>
                </div>

                <div id="div">

                    <div id="color">
                        <h6> Do you wish to add the date of the event?
                            <button class="btn" type="button" id="more" style="width: 100%;" (click)="toggleF()" style="padding:0;">
                            <i class="bi bi-chevron-compact-down" style="padding:10px;"></i></button>
                        </h6>
                    </div>
                    <div *ngIf="fecha">
                        <br>
                        <div style="width:350px; margin-left: 40%; margin-right: 40%" *ngIf="!denuncia.fecha_suceso">
                            <input type="date" class="form-control" [formControl]="fecha_suceso">
                        </div>
                        <div style="width:350px; margin-left: 40%; margin-right: 40%" *ngIf="denuncia.fecha_suceso">
                            <p>You've already stated the date of the event</p>
                        </div>
                    </div>

                </div>


                <div id="div">
                    <label for="formFileSm" class="form-label">
                    <h6>Do you wish to attach any additional documents?</h6>
                </label>
                    <div class="text-center">
                        <br>
                        <input id="formFileSm" type="file" style="width:400px" (change)="fileChangeEvent($event)" multiple>
                        <br><br>
                        <small>PDF documents, videos, jpg or png images.
                        <br>(More than one file can be added)</small>
                    </div>
                </div>

                <br><br>
                <button type="submit" class="btn btn-primary" style="float:right" (click)="onSubmit()">Confirm changes</button>
                <br><br>
            </div>

            <div class="text-center" *ngIf="denuncia.estado == 'resuelta'">
                <h5>Your communication has already been resolved, you cannot add further information</h5>
            </div>

        </div>


        <div class="text-center" *ngIf="noExiste">
            <h5>There's no communication associated with the provided ID.</h5>
        </div>
        <div class="text-center" *ngIf="testigoanadido">
            <h5>Witnesses succesfully added </h5>
        </div>
        <div class="text-center" *ngIf="implicadoanadido">
            <h5>Involved person(s) succesfully added </h5>
        </div>
        <div class="text-center" *ngIf="detalleanadido">
            <h5>Details succesfully added </h5>
        </div>
        <div class="text-center" *ngIf="archivoanadido">
            <h5>Documents succesfully added </h5>
        </div>
        <div class="text-center" *ngIf="fechaanadida">
            <h5>Event date succesfully added</h5>
        </div>

    </div>

</div>

<div style="height: 200px;"></div>