<app-pasos></app-pasos>


<div id="pasos">

    <div id="headerPasos">
        <strong>Personal information</strong>

        <p>If you prefer not to give your personal details, you can do so by indicating that you want the communication to be anonymous.
        </p>
    </div>
    <div id="bodyPasos">
        <form autocomplete="off" [formGroup]="denuncia" (ngSubmit)="onSubmit()">
            <div id="divAnonimo">
                <p id="asterisco">Do you wish to report anonymously? </p>
                <span class="swtich-container">
                    <!-- <input type="checkbox" id="switch" name="myCheckboxF" (click)="showForm()"
                       required> -->
                    <input type="checkbox" id="switch" name="myCheckboxF" formControlName="anonima">
                    <label for="switch" class="lbl"></label>
                </span>
            </div>

            <hr>

            <!-- <div *ngIf="anonimo" id="formulario" class="hide"> -->
            <div *ngIf="!denuncia.get('anonima').value" id="formulario" class="hide">
                <div class="formulario">
                    <div class="input-group mb-3">
                        <span class="input-group-text titulo">Name and surname</span>
                        <input formControlName="nombre_denunciante" type="text" aria-label="First name" class="form-control intro" aria-describedby="basic-addon1">
                        <input formControlName="apellidos_denunciante" type="text" aria-label="Last name" class="form-control intro" aria-describedby="basic-addon1">
                    </div>
                    <div class="input-group mb-3">
                        <span class="input-group-text titulo" id="basic-addon1">Email and Telephone</span>
                        <input formControlName="email_denunciante" type="email" class="form-control" aria-label="Email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" aria-describedby="basic-addon1">
                        <input formControlName="telefono_denunciante" type="text" class="form-control" aria-label="Teléfono" aria-describedby="basic-addon1">
                    </div>

                    <div class="input-group mb-3">
                        <span class="input-group-text titulo" id="basic-addon1">Position</span>
                        <input formControlName="cargo_denunciante" type="text" class="form-control" aria-label="Teléfono" aria-describedby="basic-addon1">
                    </div>

                    <div class="input-group mb-3">
                        <span class="input-group-text titulo">Province and Country</span>
                        <input formControlName="provincia_denunciante" type="text" aria-label="Provincia" class="form-control">
                        <input formControlName="pais_denunciante" type="text" aria-label="País" class="form-control">
                    </div>
                </div>
            </div>
            <div id="bottom2">
                <p>
                    This channel is confidential. Your personal data will not be shared. If someone from the organisation needs to know your data, they will request it through the channel and only if you authorise them will they be able to see it.
                </p>
            </div>
            <input type="checkbox" [formControl]="aceptado" required><span id="asterisco"> 
                I have read the  <a class="text-muted" target="_blank" routerLink="/proteccion-datos">Data Protection and Consent Notice</a>
                 and agree to the processing of my personal data in accordance with the provisions of the Data Protection and Consent Notice.</span>

            <br><br>
            <button class="btn btn-primary" (click)="anterior()">Anterior</button>
            <!-- <button class="btn btn-primary" routerLink="/dos">Anterior</button> -->


            <button [disabled]="!aceptado.value" type="submit" class="btn btn-primary" id="buttonDcha">
                Confirmar Denuncia
            </button>
            <div *ngIf="loading" style="float: right;">
                <mat-spinner diameter="35"></mat-spinner>
            </div>
        </form>
    </div>
</div>
<div class="bottommobile" style="height: 200px;"></div>

<br>