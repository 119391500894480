<div id="containerDenunciante">
    <div id="navbar">
        <ul class="nav nav-pills nav-justified">
            <li class="nav-item" routerLinkActive="active">
                <a class="nav-link" routerLink="/denunciante"><i class="bi bi-house"></i><br>Home</a>
            </li>
            <li class="nav-item" routerLinkActive="active">
                <a class="nav-link" routerLink="/extras"><i class="bi bi-file-earmark-plus"></i><br>Add details</a>
            </li>
            <li class="nav-item" routerLinkActive="active">
                <a class="nav-link" routerLink="/index"><i class="bi bi-lock"></i><br>Exit</a>
            </li>

        </ul>
    </div>


    <router-outlet></router-outlet>


</div>